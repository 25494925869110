import { IAppUser } from '@/shared/model/app-user.model';

export interface IPercentage {
  id?: number;
  value?: number;
  user?: IAppUser;
}

export class Percentage implements IPercentage {
  constructor(public id?: number, public value?: number, public user?: IAppUser) {}
}
