var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "mt-3 p-2" },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("h4", { staticClass: "mb-2" }, [_vm._v("Prozentsätze")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", disabled: !_vm.isAdmin() },
            on: {
              click: function ($event) {
                return _vm.addPercentage()
              },
            },
          },
          [_vm._v("\n      Prozentsatz hinzufügen\n    ")]
        ),
      ]),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", [
            _vm.percentages && _vm.percentages.length > 0
              ? _c(
                  "div",
                  { staticClass: "percentage-wrapper mt-3" },
                  [
                    _vm._l(_vm.percentages, function (percentage, index) {
                      return _c(
                        "div",
                        {
                          key: index + "percentage",
                          staticClass: "d-flex align-items-start",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: percentage.value,
                                    expression: "percentage.value",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  valid: _vm.isValid(percentage),
                                  invalid: !_vm.isValid(percentage),
                                },
                                attrs: {
                                  type: "number",
                                  disabled: !_vm.isAdmin(),
                                },
                                domProps: { value: percentage.value },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      percentage,
                                      "value",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.validationString(percentage))
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "ml-2 btn btn-primary",
                              attrs: {
                                disabled: !_vm.isAdmin(),
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.prepareRemove(percentage, index)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "action-wrapper" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            id: "save-changes",
                            disabled:
                              !_vm.isSaveButtonEnabled() || !_vm.isAdmin(),
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveAllPercentages()
                            },
                          },
                        },
                        [_vm._v("\n          Änderungen Speichern\n        ")]
                      ),
                      _vm._v(" "),
                      !_vm.isSaveButtonEnabled()
                        ? _c("div", { staticClass: "text-muted" }, [
                            _c(
                              "small",
                              { staticClass: "text-muted font-weight-bold" },
                              [
                                _vm._v(
                                  "\n            Bitte beheben Sie alle Fehler um die Einträge zu speichern.\n          "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.percentages.length === 0
              ? _c("div", { staticClass: "mt-3 alert alert-secondary" }, [
                  _vm._v(
                    "\n      Es sind keine Prozentsätze im System hinterlegt.\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            [
              _c("font-awesome-icon", {
                staticClass: "m-3",
                attrs: { spin: true, icon: "sync" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", { ref: "removeEntity", attrs: { id: "removeEntity" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _c(
            "span",
            {
              attrs: {
                id: "cdpRisikoApp.percentage.delete.question",
                "data-cy": "percentageDeleteDialogHeading",
              },
            },
            [_vm._v("Löschen bestätigen")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", { attrs: { id: "gen-delete-percentage-heading" } }, [
            _vm._v(
              "\n        Sind Sie sicher, dass Sie folgenden Prozentsatz löschen möchten?\n        "
            ),
            _vm.percentageToRemove
              ? _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.percentageToRemove.value) + "%"),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeDeleteDialog()
                },
              },
            },
            [_vm._v("Nicht löschen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                id: "gen-confirm-delete-percentage",
                "data-cy": "entityConfirmDeleteButton",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.removePercentage()
                },
              },
            },
            [_vm._v("\n        Löschen\n      ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }